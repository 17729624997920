.registry-preview-modal .modal-dialog {
  max-width: 1000px;
}

.registry-preview-modal .modal-content {
  border-radius: 12px;
  border: none;
}

.modal-logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.brand-text {
  font-size: 20px;
  font-weight: 600;
}

.brand-first {
  color: #00A86B;
}

.create-registry-btn {
  background: none;
  border: 1px solid #00A86B;
  color: #00A86B;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
}

.registry-photo {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
}

.registry-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.registry-title {
  font-size: 28px;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 16px;
}

.registry-description {
  color: #4B5563;
  font-size: 16px;
  line-height: 1.6;
}

.gift-form {
  background: #F9FAFB;
  border-radius: 12px;
  padding: 24px;
}

.gift-value label {
  font-size: 14px;
  color: #6B7280;
  margin-bottom: 8px;
  display: block;
}

.amount-input {
  position: relative;
  margin-bottom: 16px;
}

.amount-input .currency {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #374151;
}

.amount-input input {
  width: 100%;
  padding: 12px 12px 12px 28px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  font-size: 16px;
}

.amount-input .bitcoin {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
  font-size: 14px;
}

.preset-amounts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 24px;
}

.preset-amounts button {
  background: white;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  padding: 8px;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preset-amounts button:hover {
  border-color: #00A86B;
  color: #00A86B;
}

.send-bitcoin-btn {
  width: 100%;
  background: #00A86B;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 24px;
}

.gift-tracker h3 {
  font-size: 16px;
  color: #1F2937;
  margin-bottom: 16px;
}

.gift-list {
  max-height: 200px;
  overflow-y: auto;
}

.gift-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #E5E7EB;
  font-size: 14px;
  color: #4B5563;
}

.promotional-section {
  text-align: center;
  padding: 48px 0 24px;
  border-top: 1px solid #E5E7EB;
  margin-top: 48px;
}

.promotional-section h2 {
  font-size: 24px;
  color: #1F2937;
  margin-bottom: 16px;
}

.promotional-section > p {
  color: #6B7280;
  margin-bottom: 48px;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.feature img {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
}

.feature h3 {
  font-size: 18px;
  color: #1F2937;
  margin-bottom: 8px;
}

.feature p {
  font-size: 14px;
  color: #6B7280;
}
