.bfb-page {
  padding: 40px 0;
  background: #FFFFFF;
}

.hero-section {
  margin-bottom: 120px;
}

.hero-content {
  padding-right: 48px;
}

h1 {
  font-size: 48px;
  font-weight: 700;
  color: #1F2937;
  margin-bottom: 24px;
  line-height: 1.1;
}

.hero-text {
  font-size: 20px;
  color: #4B5563;
  margin-bottom: 24px;
  line-height: 1.6;
}

.hero-subtext {
  font-size: 18px;
  color: #6B7280;
  margin-bottom: 32px;
  line-height: 1.6;
}

.cta-button {
  background: linear-gradient(135deg, #9333ea, #c084fc);
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 12px #9333ea33;
  color: #fff;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 16px 32px;
  transition: transform .2s ease, box-shadow .2s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(147, 51, 234, 0.3);
}

.gift-img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  display: block;
  margin: 0 auto;
}

.why-section {
  text-align: center;
  padding: 80px 0;
}

.why-section h2 {
  font-size: 40px;
  color: #1F2937;
  margin-bottom: 64px;
}

.features-grid {
  margin-top: 48px;
}

.feature-card {
  padding: 32px;
  text-align: center;
}

.feature-icon {
  width: 64px;
  height: 64px;
  background: #FFF4E5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: #FF8C00;
  font-size: 24px;
}

.feature-card h3 {
  font-size: 24px;
  color: #1F2937;
  margin-bottom: 16px;
}

.feature-card p {
  color: #6B7280;
  line-height: 1.6;
}

.cta-section {
  text-align: center;
  padding: 80px 0;
  background: #FFF4E5;
  border-radius: 16px;
  margin: 40px 0;
}

.cta-section h2 {
  font-size: 40px;
  color: #1F2937;
  margin-bottom: 16px;
}

.cta-section p {
  font-size: 20px;
  color: #4B5563;
  margin-bottom: 32px;
}

.hero-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }
  
  .hero-text, .hero-subtext {
    text-align: center;
  }
  
  .features-grid {
    gap: 32px;
  }
}
