.registry-page {
  background: #fff;
}

.registry-header {
  border-bottom: 1px solid #E5E7EB;
  padding: 16px 0;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
}

.brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand-text {
  font-size: 24px;
  font-weight: 600;
}

.brand-first {
  color: #FF8C00;
}

.brand-second {
  color: #1F2937;
}

/* Main Content */
.registry-content {
  padding: 40px 0;
}

.registry-photo {
  width: 100%;
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 32px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.registry-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.registry-title {
  font-size: 36px;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 24px;
}

.registry-description {
  color: #4B5563;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 32px;
}

.invite-friends-btn {
  background: none;
  border: 2px solid #FF8C00;
  color: #FF8C00;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 40px;
  transition: all 0.2s ease;
}

.invite-friends-btn:hover {
  background: #FF8C00;
  color: white;
}

/* Gift Form Section */
.gift-form {
  background: #F9FAFB;
  border-radius: 16px;
  padding: 32px;
  position: sticky;
  top: 100px;
}

.gift-value label {
  font-size: 16px;
  color: #4B5563;
  margin-bottom: 12px;
  display: block;
  font-weight: 500;
}

.amount-input {
  position: relative;
  margin-bottom: 24px;
}

.amount-input .currency {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #374151;
  font-weight: 500;
}

.amount-input input {
  width: 100%;
  padding: 16px 16px 16px 36px;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  font-size: 18px;
  background: white;
}

.amount-input input:focus {
  outline: none;
  border-color: #FF8C00;
  box-shadow: 0 0 0 3px rgba(255, 140, 0, 0.1);
}

.amount-input .bitcoin {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
  font-size: 14px;
}

.preset-amounts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 32px;
}

.preset-amounts button {
  background: white;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  padding: 12px;
  color: #374151;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preset-amounts button:hover {
  border-color: #FF8C00;
  color: #FF8C00;
}

.send-bitcoin-btn {
  width: 100%;
  background: #FF8C00;
  color: white;
  border: none;
  padding: 16px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 32px;
  transition: all 0.2s ease;
}

.send-bitcoin-btn:hover {
  background: #e67e00;
}

/* Gift Tracker */
.gift-tracker {
  border-top: 1px solid #E5E7EB;
  padding-top: 24px;
}

.gift-tracker h3 {
  font-size: 18px;
  color: #1F2937;
  margin-bottom: 16px;
  font-weight: 500;
}

.gift-list {
  max-height: 240px;
  overflow-y: auto;
}

.gift-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #E5E7EB;
  font-size: 16px;
  color: #4B5563;
}

/* Promotional Section */
.promotional-section {
  text-align: center;
  padding: 80px 0 40px;
  border-top: 1px solid #E5E7EB;
  margin-top: 80px;
}

.promotional-section h2 {
  font-size: 32px;
  color: #1F2937;
  margin-bottom: 24px;
}

.promotional-section > p {
  color: #6B7280;
  font-size: 18px;
  margin-bottom: 64px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
  max-width: 1000px;
  margin: 0 auto;
}

.feature {
  text-align: center;
}

.feature h3 {
  font-size: 24px;
  color: #1F2937;
  margin-bottom: 16px;
}

.feature p {
  font-size: 16px;
  color: #6B7280;
  line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .registry-photo {
    height: 300px;
  }

  .registry-title {
    font-size: 28px;
  }

  .features {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .gift-form {
    margin-top: 40px;
    position: static;
  }
}

/* Add to existing styles */

.placeholder-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f4f6;
  color: #9ca3af;
}

.placeholder-image p {
  margin-top: 16px;
  font-size: 16px;
}

.feature-icon {
  width: 64px;
  height: 64px;
  background: #FFF4E5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: #FF8C00;
}
