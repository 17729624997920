/* Carousel Section */

.carousel-title {
  font-size: 2rem;
  color: #F1EFE9;
}

.carousel-title,
.carousel-subtitle,
.marketing-title,
.feature-title,
.card-title,
.modal-title {
  font-family: 'Styrene Bold', sans-serif;
}

.carousel-text,
.marketing-text,
.feature-text,
.card-text,
.modal-text {
  font-family: 'Tiempos Regular', serif;
}

.full-width-carousel {
  width: 100%;
  margin-top: 0px;
}

.carousel-image-container {
  height: 500px !important; /* Force 500px height */
  overflow: hidden;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.full-width-carousel .carousel-caption {
  bottom: 1rem;
  z-index: 10;
  text-align: center;
}

.carousel-button {
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .carousel-title {
    font-size: 2.5rem;
    color: #F1EFE9;
  }
  .carousel-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.marketing-section {
  padding: 20px 0; /* Minimal padding */
  background: linear-gradient(135deg, #FFF6F2 0%, #FFF 100%);
  width: 100%;
  margin-bottom: 20px;
}

.marketing-section::before {
  content: '';
  position: absolute;
  width: 200px; /* Reduced from 300px */
  height: 200px; /* Reduced from 300px */
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 153, 0, 0.1) 0%, rgba(255, 122, 0, 0.1) 100%);
  top: -100px; /* Adjusted position */
  left: -100px;
  z-index: 0;
}

.marketing-section::after {
  content: '';
  position: absolute;
  width: 150px; /* Reduced from 200px */
  height: 150px; /* Reduced from 200px */
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 153, 0, 0.08) 0%, rgba(255, 122, 0, 0.08) 100%);
  bottom: -75px;
  right: -75px;
  z-index: 0;
}

.marketing-title {
  text-align: center;
  font-size: 2.25rem; /* Increased from 2rem */
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.marketing-text {
  font-size: 1.5rem;
  text-align: center;
  color: #666;
  margin: 0 auto;
}

.howitworks-section {
  padding: 0px 0;
}

.howitworks-title {
  text-align: center;
}

.howitworks-text {
  font-size: 1rem;
  text-align: center;
}

.howitworks-row {
  height: 300px;
}

.howitworks-text h4 {
  font-family: 'Styrene Bold', sans-serif;
}

.howitworks-text p {
  font-family: 'Tiempos Regular', serif;
}

.howitworks-image img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.feature-title {
  font-size: 1.3rem;
}

.custom-bg-color {
  background-color: #f8f9fa;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.registries-section {
  padding: 20px;
  text-align: center;
}

.section-container {
  padding: 80px 0;
  margin: 40px 0;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.03);
}

.registry-cards-section {
  padding: 40px 0;
  width: 100%;
  background: #fff;
}

/* Registry Cards Section */
.registry-card {
  height: 100%;
  border: none;
  border-radius: 12px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  background: #fff;
}

.registry-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

.registry-card .card-img-top {
  border-radius: 12px 12px 0 0;
  height: 200px;
  object-fit: cover;
}

.registry-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #2D3047;
}

.registry-card .card-text {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .marketing-title {
    font-size: 2.5rem;
    padding: 0 20px;
  }
  
  .marketing-text {
    font-size: 1.1rem;
    padding: 0 20px;
  }
  
  .marketing-section {
    padding: 80px 0 60px;
  }
}

/* Combined Marketing and Registry Section */
.marketing-and-registry-section {
  padding: 40px 0;
  background: linear-gradient(135deg, #FFF6F2 0%, #FFF 100%);
  width: 100%;
}

.marketing-content {
  margin-bottom: 40px;
}

.marketing-text {
  font-size: 1rem;
  text-align: center;
  color: #666;
  margin: 0 auto;
  margin-bottom: 2rem;
}

/* Registry Cards */
.registry-card {
  height: 100%;
  border: none;
  border-radius: 12px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  background: #fff;
}

.registry-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

.registry-card .card-img-top {
  border-radius: 12px 12px 0 0;
  height: 200px;
  object-fit: cover;
}

.registry-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #2D3047;
}

.registry-card .card-text {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
}

.cta-container {
  text-align: center;
  padding-top: 40px;
  position: relative;
  z-index: 2;
}

.cta-button {
  background: linear-gradient(135deg, #9333ea, #c084fc);
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 12px #9333ea33;
  color: #fff;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 16px 32px;
  transition: transform .2s ease, box-shadow .2s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(147, 51, 234, 0.3);
}

.cta-button:active {
  transform: translateY(0);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .cta-container {
    padding-top: 30px;
  }
  
  .cta-button {
    padding: 14px 28px;
    font-size: 1rem;
  }
}
