/* index.css */
@import url('./fonts.css');

body {
  margin: 0;
  font-family: 'Tiempos Regular', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1EFE9; /* Claude background */
  color: #29261B; /* Claude text */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Styrene Bold', sans-serif;
}

.custom-bg-color {
  background-color: #F8F8F6; /* Example: light gray background */
}

.custom-switch {
  display: flex;
  align-items: center;
}

.custom-switch span {
  color: #fff; /* Adjust based on your navbar's color scheme */
}

.custom-toggle {
  display: flex;
  align-items: center;
}

.label-text {
  color: #fff; /* or any color that fits the navbar theme */
  margin: 0 5px; /* Adjust spacing around the switch */
}

.form-inline .form-check {
  align-self: center;
}

.brand-logo {
  font-size: 1.5rem;
}

.nav-link {
  margin-left: 20px;
  color: #fff;
}

.nav-link svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

.nav-link:hover svg {
  fill: #61dafb;
}

.nav-link.active svg {
  fill: #61dafb;
}

.btn-custom {
  font-family: 'Styrene Bold', sans-serif;
}

.navbar-logo {
  height: 1.5em;
  vertical-align: middle;
}

.navbar-icon {
  height: 1.5em;
  width: 1.5em;
  vertical-align: middle;
}

.navbar-button {
  margin: 0;
  padding: 0;
}

.feature-block {
  padding: 20px;
  margin-bottom: 20px;
  border-left: 4px solid #00a86b;
  background: #f8f9fa;
}

.charity-section, .wedding-section {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
}

.benefit-item {
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  background: rgba(0, 168, 107, 0.1);
}

.charity-features .feature {
  margin: 20px 0;
  padding-left: 15px;
  border-left: 3px solid #00a86b;
}
