.signup-wizard {
  margin-top: 80px;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
  background: #fff;
}

.step-indicator {
  margin-bottom: 2rem;
}

.step-number {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.step-progress {
  height: 0.5rem;
  background-color: #e9ecef;
}

.step-progress .progress-bar {
  background-color: #FF8C00; /* Changed from #00A86B to orange */
}

h2 {
  color: #2D3047;
  font-weight: 600;
}

.form-label {
  font-weight: 500;
  color: #2D3047;
}

.form-control {
  padding: 0.75rem;
  border-radius: 0.5rem;
}

.form-control:focus {
  border-color: #FF8C00; /* Changed from #00A86B to orange */
  box-shadow: 0 0 0 0.2rem rgba(255, 140, 0, 0.25); /* Adjusted alpha shadow */
}

.btn-primary {
  background-color: #FF8C00; /* Changed from #00A86B to orange */
  border-color: #FF8C00;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
}

.btn-primary:hover {
  background-color: #e67e00; /* Slightly darker orange for hover */
  border-color: #e67e00;
}

.btn-outline-secondary {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
}

/* Add to existing styles */
.preview-actions {
  text-align: center;
}

.photo-preview {
  margin: 20px 0;
  border-radius: 12px;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto;
}

.preview-image {
  width: 100%;
  height: auto;
  display: block;
}

.wizard-btn-secondary {
  padding: 10px 24px;
  border: 1px solid #E5E7EB;
  color: #374151;
}

.wizard-btn-outline {
  padding: 10px 24px;
  border: 2px solid #FF8C00;
  color: #FF8C00;
}

.wizard-btn-outline:hover {
  background: #FF8C00;
  color: white;
}

.wizard-btn-primary {
  padding: 10px 24px;
  background: #FF8C00;
  border: none;
  color: white;
}

.wizard-btn-primary:hover {
  background: #e67e00;
}

.gap-3 {
  gap: 1rem;
}
