/* HomeCarousel.css */
.full-width-carousel {
  width: 100%;
  margin-top: 0px;
}

.carousel-image-container {
  height: 500px;
  overflow: hidden;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

/* Subtle gradient overlay */
.carousel-image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

.full-width-carousel .carousel-caption {
  bottom: 1rem;
  z-index: 10;
  text-align: center;
}

/* Title and subtitle container to control spacing */
.carousel-text-container {
  margin-bottom: 1rem;
}

.carousel-title {
  margin-bottom: 0.25rem; /* Reduced from default */
}

.carousel-subtitle {
  font-size: 1.25rem;
  margin-top: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.carousel-button {
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .carousel-subtitle {
    font-size: 1.4rem;
  }

  .carousel-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}