.howitworks-container {
  background: linear-gradient(135deg, #f5f9ff 0%, #fff 100%);
  padding: 40px 0;
  position: relative;
  overflow: hidden;
  margin-top: -1px;
}

/* Subtle background decoration */
.howitworks-container::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 0% 0%, rgba(255, 153, 0, 0.03) 0%, transparent 50%);
  top: 0;
  left: 0;
}

.howitworks-title {
  text-align: center;
  font-size: 3rem; /* Reduced from 3.5rem */
  margin-bottom: 20px; /* Reduced from 40px */
  background: linear-gradient(135deg, #FF9900 0%, #FF7A00 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  z-index: 1;
  font-weight: 700;
}

.howitworks-step {
  margin-bottom: 30px; /* Reduced from 60px */
  position: relative;
  z-index: 1;
}

.howitworks-step:last-child {
  margin-bottom: 0; /* Remove margin from last step */
}

.howitworks-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.howitworks-image {
  width: 400px; /* Maintain width */
  height: 200px; /* Changed to 2:1 aspect ratio */
  object-fit: cover;
  border-radius: 16px; /* Slightly reduced for new proportions */
  transition: transform 0.3s ease;
}

/* Optional: Add a subtle shadow on hover */
.howitworks-image:hover {
  transform: translateY(-5px);
}

.step-content {
  padding: 0 40px;
  position: relative;
}

.step-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #FF9900;
  margin-bottom: 1rem;
  display: block;
  font-weight: 600;
}

.step-content h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2D3047;
  font-weight: 700;
  line-height: 1.2;
}

.step-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .howitworks-container {
    padding: 30px 0; /* Reduced from 60px */
  }

  .howitworks-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }

  .howitworks-step {
    margin-bottom: 60px;
  }

  .howitworks-image {
    width: 300px;
    height: 150px; /* Maintain 2:1 aspect ratio */
  }

  .howitworks-image-col {
    height: auto; /* Remove fixed height */
    margin-bottom: 15px; /* Reduced spacing */
  }

  .step-content {
    padding: 0 20px;
    text-align: center;
  }

  .step-content h3 {
    font-size: 1.75rem;
  }
}

/* Add subtle animation on scroll */
.howitworks-step {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Stagger the animations */
.howitworks-step:nth-child(2) {
  animation-delay: 0.2s;
}

.howitworks-step:nth-child(3) {
  animation-delay: 0.4s;
}

/* Add this to ensure CTA container has same background */
.howitworks-container .cta-container {
  background: inherit;
  position: relative;
  z-index: 2;
}
