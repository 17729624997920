.modern-footer {
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  margin-top: 0;
}

.footer-brand {
  font-size: 1.2rem;
}

.brand-first {
  font-weight: 700;
  background: linear-gradient(135deg, #9333EA 0%, #C084FC 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.brand-second {
  font-weight: 300;
  color: #2D3047;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.footer-links a:hover {
  color: #9333EA;
}

.separator {
  color: #666;
  font-size: 0.9rem;
}

.copyright {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .modern-footer {
    padding: 15px 0;
  }

  .footer-brand,
  .footer-links,
  .copyright {
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-links {
    flex-wrap: wrap;
    justify-content: center;
  }
}
