.bitcoin-lock-section {
  background: linear-gradient(135deg, #f5f9ff 0%, #fff 100%);
  padding: 40px 0 80px; /* Reduced top padding from 80px to 40px */
  position: relative;
  overflow: hidden;
  margin-top: -1px; /* Remove gap */
}

/* Decorative elements */
.decorative-line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgba(79, 127, 255, 0.5), transparent);
}

.tech-grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(rgba(79, 127, 255, 0.03) 1px, transparent 1px),
    linear-gradient(90deg, rgba(79, 127, 255, 0.03) 1px, transparent 1px);
  background-size: 30px 30px;
  transform: perspective(500px) rotateX(60deg);
  transform-origin: top;
  opacity: 0.5;
}

.section-header {
  text-align: center;
  margin-bottom: 40px; /* Reduced from 60px */
  position: relative;
  z-index: 2;
}

.section-header h2 {
  font-size: 3.5rem;
  margin-bottom: 15px; /* Reduced from 20px */
  background: linear-gradient(135deg, #4F7FFF 0%, #56CCF2 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.feature-content {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 40px;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(79, 127, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.feature-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(79, 127, 255, 0.05) 0%, rgba(86, 204, 242, 0.05) 100%);
  border-radius: 24px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(79, 127, 255, 0.08);
}

.feature-content:hover::before {
  opacity: 1;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 20px;
  position: relative;
}

.feature-content h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #2D3047;
  position: relative;
}

.feature-content p {
  color: #666;
  line-height: 1.6;
  margin: 0;
  position: relative;
}

/* Floating particles */
@keyframes float {
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(180deg); }
  100% { transform: translateY(0px) rotate(360deg); }
}

.bitcoin-lock-section::before,
.bitcoin-lock-section::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  background: linear-gradient(135deg, rgba(79, 127, 255, 0.1), rgba(86, 204, 242, 0.1));
  border-radius: 50%;
  filter: blur(80px);
  animation: float 15s infinite ease-in-out;
}

.bitcoin-lock-section::before {
  top: -150px;
  right: -150px;
  animation-delay: -7.5s;
}

.bitcoin-lock-section::after {
  bottom: -150px;
  left: -150px;
}

@media (max-width: 768px) {
  .section-header h2 {
    font-size: 2.5rem;
  }
  
  .feature-content {
    margin-bottom: 20px;
  }
}
