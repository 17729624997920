.faq-section {
  background: linear-gradient(135deg, #fcfaff 0%, #fff 100%);
  padding: 40px 0;
  position: relative;
  overflow: hidden;
  margin-top: -1px;
  margin-bottom: 0; /* Ensure no space before footer */
}

.tech-grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(rgba(147, 51, 234, 0.03) 1px, transparent 1px),
    linear-gradient(90deg, rgba(147, 51, 234, 0.03) 1px, transparent 1px);
  background-size: 30px 30px;
  transform: perspective(500px) rotateX(60deg);
  transform-origin: top;
  opacity: 0.5;
}

.faq-title {
  text-align: center;
  font-size: 3.5rem;
  margin-bottom: 30px;
  background: linear-gradient(135deg, #9333EA 0%, #C084FC 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

/* Custom Accordion Styling */
.accordion-item {
  border: 1px solid rgba(147, 51, 234, 0.1);
  border-radius: 12px !important;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
}

.accordion-header {
  border-radius: 12px;
}

.accordion-button {
  padding: 20px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #2D3047;
  background: transparent;
  border-radius: 12px !important;
}

.accordion-button:not(.collapsed) {
  color: #9333EA;
  background: transparent;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(147, 51, 234, 0.2);
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239333EA'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-body {
  padding: 20px 24px;
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

/* Decorative elements */
.faq-section::before,
.faq-section::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  background: linear-gradient(135deg, rgba(147, 51, 234, 0.1), rgba(192, 132, 252, 0.1));
  border-radius: 50%;
  filter: blur(80px);
}

.faq-section::before {
  top: -150px;
  right: -150px;
}

.faq-section::after {
  bottom: -150px;
  left: -150px;
}

@media (max-width: 768px) {
  .faq-section {
    padding: 30px 0;
  }

  .faq-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .accordion-button {
    font-size: 1rem;
    padding: 16px 20px;
  }

  .accordion-body {
    padding: 16px 20px;
  }
}
