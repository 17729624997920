/* PolicyPage.css */
[name="termly-embed"] {
  font-family: 'Tiempos Regular', serif;
  line-height: 1.6;
}

[name="termly-embed"] h1,
[name="termly-embed"] h2,
[name="termly-embed"] h3,
[name="termly-embed"] h4,
[name="termly-embed"] h5,
[name="termly-embed"] h6 {
  font-family: 'Styrene Bold', sans-serif;
}