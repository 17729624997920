.modern-navbar {
  background: linear-gradient(90deg, rgba(255,255,255,0.98) 0%, rgba(255,246,242,0.98) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 20px rgba(0,0,0,0.04);
  padding: 1rem 0;
  width: 100%;
  z-index: 1000;
}

.brand-logo {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: -0.02em;
}

.brand-text {
  background: linear-gradient(135deg, #FF9900 0%, #FF7A00 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.brand-first {
  font-weight: 800;
}

.brand-second {
  font-weight: 600;
  opacity: 0.9;
}

.nav-link {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #1A1A1A !important;
  margin: 0 1rem;
  transition: all 0.3s ease;
}

/* Add smooth scroll padding for fixed navbar */
html {
  scroll-padding-top: 80px;
}

/* Responsive adjustments */
@media (max-width: 991px) {
  .get-started-btn {
    margin: 1rem 0;
    width: 100%;
  }
  
  .nav-link {
    margin: 0.5rem 0;
  }
}

/* Add subtle animation for navbar on scroll */
.modern-navbar.scrolled {
  background: rgba(255,255,255,0.98);
  backdrop-filter: blur(20px);
}

.gift-bitcoin-btn {
  background: linear-gradient(135deg, #9333ea, #c084fc);
  border: none;
  border-radius: 30px;
  color: white;
  padding: 8px 20px;
  font-weight: 500;
  transition: all 0.2s ease;
  margin-left: 16px;
  text-decoration: none;
}

.gift-bitcoin-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(147, 51, 234, 0.2);
  color: white;
}

@media (max-width: 991px) {
  .gift-bitcoin-btn {
    margin: 8px 0;
    display: inline-block;
  }
}

.create-registry-btn {
  background: linear-gradient(135deg, #9333ea, #c084fc);
  border: none;
  border-radius: 30px;
  color: white;
  padding: 8px 20px;
  font-weight: 500;
  transition: all 0.2s ease;
  margin-left: 16px;
  text-decoration: none;
}

.create-registry-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(147, 51, 234, 0.2);
  color: white;
}

@media (max-width: 991px) {
  .create-registry-btn {
    margin: 8px 0;
    display: inline-block;
  }
}
