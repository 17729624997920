/* src/components/VoiceAssistant.css */
/* Floating microphone button */
.voice-assistant-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 56px;
    height: 56px;
    background: linear-gradient(135deg, #9333ea, #c084fc);
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(147, 51, 234, 0.3);
    transition: all 0.2s ease;
    z-index: 1000;
  }
  
  .voice-assistant-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(147, 51, 234, 0.4);
  }
  
  /* Voice Assistant Modal */
  .voice-assistant-content {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(135deg, #9333ea, #c084fc);
    border-radius: 24px;
    padding: 12px 20px;
    color: white;
    min-width: 300px;
    max-width: 400px;
    z-index: 1001;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(147, 51, 234, 0.3);
  }
  
  /* Compact View */
  .voice-assistant-compact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  
  .compact-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .assistant-title {
    font-weight: 500;
    font-size: 14px;
  }
  
  .timer {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
  
  .assistant-controls {
    display: flex;
    gap: 8px;
  }
  
  /* Expanded View */
  .voice-assistant-content.expanded {
    min-height: 260px;
    padding: 20px;
  }
  
  .expanded-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .header-controls {
    display: flex;
    gap: 8px;
  }
  
  .expanded-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  
  .visualization-container {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 12px;
    backdrop-filter: blur(8px);
  }
  
  .audio-visualizer {
    width: 100%;
    height: 100%;
  }
  
  .expanded-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
  
  .state-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
  
  .state-icon {
    animation: pulse 2s infinite;
  }
  
  .powered-by {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    margin-top: 16px;
  }
  
  /* Control Buttons */
  .control-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .control-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-1px);
  }
  
  .control-button:active {
    transform: translateY(0);
  }
  
  /* Animations */
  @keyframes pulse {
    0% { opacity: 0.4; }
    50% { opacity: 1; }
    100% { opacity: 0.4; }
  }
  
  /* State-specific styles */
  .voice-assistant-content[data-state="listening"] .audio-visualizer {
    --bar-color: rgba(255, 255, 255, 0.9);
  }
  
  .voice-assistant-content[data-state="speaking"] .audio-visualizer {
    --bar-color: rgba(255, 255, 255, 0.9);
  }
  
  .voice-assistant-content[data-state="thinking"] .audio-visualizer {
    --bar-color: rgba(255, 255, 255, 0.6);
  }