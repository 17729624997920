/* fonts.css */
@font-face {
  font-family: 'Styrene';
  src: url('./fonts/Styrene.woff2') format('woff2'),
       url('./fonts/Styrene.woff') format('woff'),
       url('./fonts/Styrene.ttf') format('truetype'),
       url('./fonts/Styrene.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos Regular';
  src: url('./fonts/TiemposRegular.woff2') format('woff2'),
       url('./fonts/TiemposRegular.woff') format('woff'),
       url('./fonts/TiemposRegular.ttf') format('truetype'),
       url('./fonts/TiemposRegular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Styrene Regular';
  src: url('./fonts/StyreneRegular.woff2') format('woff2'),
       url('./fonts/StyreneRegular.woff') format('woff'),
       url('./fonts/StyreneRegular.ttf') format('truetype'),
       url('./fonts/StyreneRegular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Styrene Medium';
  src: url('./fonts/StyreneMedium.woff2') format('woff2'),
       url('./fonts/StyreneMedium.woff') format('woff'),
       url('./fonts/StyreneMedium.ttf') format('truetype'),
       url('./fonts/StyreneMedium.eot') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Styrene Bold';
  src: url('./fonts/StyreneBold.woff2') format('woff2'),
       url('./fonts/StyreneBold.woff') format('woff'),
       url('./fonts/StyreneBold.ttf') format('truetype'),
       url('./fonts/StyreneBold.eot') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}